<!-- 佣金 -->
<template>
  <page-container class="billing-container">
    <!-- 下拉刷新 -->
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        style="position: relative;z-index: 1"
    >
      <template #loading>
        {{ pagination.pageNum < 2 ? '' : '加载中...' }}
      </template>

      <!-- 更多列表加载 -->
      <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedTextRender()"
          @load="onLoad"
      >
        <!-- 佣金 -->
        <div
            class="billing-item"
            v-for="item of dataList"
        >
          <div
              class="billing-title"
              :style="{ color: getColor(item) }"
          >
            {{ item.title }}

            <span
                v-if="item.title === '解锁推广金额收入'"
                class="unlock-detail-btn"
                @click="handleLockDetail(item.id)"
            >
               明细<van-icon name="arrow"/>
            </span>
          </div>
          <div>
            <label>金额：</label>
            <span class="billing-amount-change" :style="{ color: getColor(item) }">{{ item.amountChange }}元</span>
          </div>
          <div v-if="item.orderNo">
            <label>订单号：</label>
            <span>{{ item.orderNo }}</span>
          </div>
          <div>
            <label>创建时间：</label>
            <span>{{ item.createTime }}</span>
          </div>
          <div
              v-if="item.memo && item.title !== item.memo"
              class="memo-text"
          >
            {{ item.memo }}
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <van-dialog
        v-model="lockDetailDialog.show"
        title="解锁推广金额收入明细"
        class="lock-detail-dialog"
        :close-on-click-overlay="true"
        confirm-button-color="#ff7510"
    >
      <lock-detail
          v-if="lockDetailDialog.show"
          :id="lockDetailDialog.id"
      />
    </van-dialog>

  </page-container>
</template>

<script>
import { getUserBilling } from '@/api/user.js';
// import { getBillingPageTestApi as getUserBilling } from '@/test/billing';
import { Dialog } from 'vant';
import LockDetail from '@/views/billing/lockDetail';

export default {
  name: 'billing',
  components: {
    LockDetail,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },

      lockDetailDialog: {
        show: false,
        id: '',
      },
    };
  },
  methods: {
    getColor({ amountChange }) {
      if (amountChange > 0) {
        return '#00b532';
      }
      if (amountChange < 0) {
        return 'red';
      }
    },
    handleLockDetail(id) {
      Object.assign(this.lockDetailDialog, {
        show: true,
        id,
      });
    },

    onLoad() {
      this.pagination.pageNum++;
      let {
        searchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getUserBilling({
        ...searchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },
    finishedTextRender() {
      let { loading, finished, dataList } = this;
      if (!loading && finished) {
        return dataList.length ? '没有更多了' : '暂无相关数据';
      }
    },
  },
};
</script>

<style lang="less">
.billing-container {
  .billing-item {
    margin: 10px;
    padding: 10px 16px;
    color: #333;
    background-color: #fff;
    border-radius: 10px;

    .billing-title {
      font-size: 16px;
      color: #333;

      .unlock-detail-btn {
        float: right;
        font-size: 14px;
        color: #333;
      }
    }

    & > div {
      margin: 10px 0;
      font-size: 14px;
      color: #888;

      & > label {
        display: inline-block;
        width: 5.5em;
        color: #999;
      }

      .billing-amount-change {
        font-size: 18px;
      }

      &.memo-text {
        padding: 14px 0 0 0;
        color: #444;
        border-top: 1px solid #f1f1f1;
      }
    }
  }
}

.lock-detail-dialog {
  .van-dialog__content {
    max-height: 61.8vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
