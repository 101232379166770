<!-- 解锁推广金额收入明细 -->
<template>
  <div class="lock-detail-container">
    <div v-if="loading" style="padding: 2em 0;text-align: center;overflow: hidden;">
      <van-loading/>
    </div>
    <div
        class="billing-item"
        v-for="item of dataList"
    >
      <div
          class="billing-title"
          :style="{ color: getColor(item) }"
      >
        {{ item.title }}
      </div>
      <div>
        <label>金额：</label>
        <span class="billing-amount-change" :style="{ color: getColor(item) }">{{ item.amountChange }}元</span>
      </div>
      <div v-if="item.orderNo">
        <label>订单号：</label>
        <span>{{ item.orderNo }}</span>
      </div>
      <div>
        <label>创建时间：</label>
        <span>{{ item.createTime }}</span>
      </div>
      <div
          v-if="item.memo && item.title !== item.memo"
          class="memo-text"
      >
        {{ item.memo }}
      </div>
    </div>

  </div>
</template>

<script>
import { getLockDetail } from '@/api/user.js';
// import { getLockDetailTestApi as getLockDetail } from '@/test/billing';

export default {
  name: 'lockDetail',
  props: {
    id: {
      default: () => '',
    }
  },
  data() {
    return {
      dataList: [],
      loading: false,
    };
  },
  created() {
    this.$watch('id', () => {
      if (this.id) {
        this.loading = true;
        getLockDetail({ id: this.id }).then(res => {
          this.dataList = Array.isArray(res) ? res : [];
          this.loading = false;
        });
      }
    }, { immediate: true });
  },
  methods: {
    getColor({ amountChange }) {
      if (amountChange > 0) {
        return '#00b532';
      }
      if (amountChange < 0) {
        return 'red';
      }
    },
  },
};
</script>

<style lang="less">
.lock-detail-container {
  .billing-item {
    margin: 10px;
    padding: 10px 16px;
    color: #333;
    background-color: #fff;
    border-radius: 10px;

    .billing-title {
      font-size: 16px;
      color: #333;

      .unlock-detail-btn {
        float: right;
        font-size: 14px;
        color: #333;
      }
    }

    & > div {
      margin: 10px 0;
      font-size: 14px;
      color: #888;

      & > label {
        display: inline-block;
        width: 5.5em;
        color: #999;
      }

      .billing-amount-change {
        font-size: 18px;
      }

      &.memo-text {
        padding: 14px 0 0 0;
        color: #444;
        border-top: 1px solid #f1f1f1;
      }
    }
  }
}
</style>
